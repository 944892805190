import { Grid } from '@mui/material';
import { CountUp } from 'countup.js';
import React, { useEffect, useRef } from 'react';
import {
    BookOpenIcon,
    DocumentTextIcon,
} from '@heroicons/react/24/outline/index.js';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BrushIcon from '@mui/icons-material/Brush';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DnsIcon from '@mui/icons-material/Dns';

export default function Counter({ counters }) {
    const subjectCountUpRef = useRef(null);
    const programCountUpRef = useRef(null);
    const themeCountUpRef = useRef(null);
    const premiseCountUpRef = useRef(null);

    let anim = {};

    useEffect(() => {
        initCountUp();
    }, [counters]);

    async function initCountUp() {
        const countUps = [
            {
                ref: programCountUpRef,
                anim: 'program',
            },
            {
                ref: subjectCountUpRef,
                anim: 'subject',
            },
            {
                ref: themeCountUpRef,
                anim: 'theme',
            },
            {
                ref: premiseCountUpRef,
                anim: 'premise',
            },
        ];

        for (const index in countUps) {
            const countUp = countUps[index];

            const to = counters[`${countUp.anim}s_count`]
                ? counters[`${countUp.anim}s_count`]
                : 0;
            anim[countUp.anim] = new CountUp(countUp.ref.current, to);
            if (!anim[countUp.anim].error) {
                anim[countUp.anim].start();
            } else {
                console.error(anim[countUp.anim].error);
            }
        }
    }

    return (
        <>
            <div className="mt-3 flex flex-col md:items-end">
                <div className="rounded border p-2 dark:border-white dark:text-white">
                    <div className="flex items-center">
                        <LibraryBooksIcon className="mr-2 mt-0.5 size-3" />
                        Programmes:
                        <span className="ml-2" ref={programCountUpRef}>
                            0
                        </span>
                    </div>
                    <div className="flex items-center">
                        <BookmarkIcon className="mr-2 mt-0.5 size-3" />
                        Subjects:
                        <span className="ml-2" ref={subjectCountUpRef}>
                            0
                        </span>
                    </div>
                    <div className="flex items-center">
                        <BrushIcon className="mr-2 mt-0.5 size-3" />
                        Themes:
                        <span className="ml-2" ref={themeCountUpRef}>
                            0
                        </span>
                    </div>
                    <div className="flex items-center">
                        <DnsIcon className="mr-2 mt-0.5 size-3" />
                        Premises:
                        <span className="ml-2" ref={premiseCountUpRef}>
                            0
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
