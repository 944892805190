import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Swal from 'sweetalert2';

const formatSelect = (data, label, value) => {
    if (!data) {
        return [];
    }

    const newData = [];

    data.map((datum) => {
        newData.push({
            value: datum[value],
            label: datum[label],
        });
    });

    return newData;
};

const MoveModal = ({ setOpenModal, openModal, refresh, node }) => {
    const [faculties, setFaculties] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [themes, setThemes] = useState([]);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            faculty_id: null,
            program_id: null,
            subject_id: null,
            theme_id: null,
        },
        mode: 'onChange',
    });

    const faculty_id = watch('faculty_id');
    const program_id = watch('program_id');
    const subject_id = watch('subject_id');
    const theme_id = watch('theme_id');

    const form = useRef(null);

    useEffect(() => {
        getFaculties();
    }, [node]);

    useEffect(() => {
        if (faculty_id) {
            getPrograms();
        }
    }, [faculty_id]);

    useEffect(() => {
        if (program_id) {
            getSubjects();
        }
    }, [program_id]);

    useEffect(() => {
        if (subject_id) {
            getThemes();
        }
    }, [subject_id]);

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            const tmp = formatSelect(faculties, 'name', 'id');
            setFaculties(tmp);

            setValue(
                'faculty_id',
                tmp.filter((item) => item.value == node.params.faculty_id)[0]
            );
        } else {
            toast.error(ret.data.message);
        }
    };

    const getPrograms = async () => {
        const params = {
            faculty_id: faculty_id,
        };
        const ret = await axiosPost('/admin/program/getList', params);
        if (ret.status == 200) {
            const programs = ret.data.data;
            const tmp = formatSelect(programs, 'name', 'id');
            setPrograms(tmp);

            setValue(
                'program_id',
                tmp.filter((item) => item.value == node.params.program_id)[0]
            );
        } else {
            toast.error(ret.data.message);
        }
    };

    const getSubjects = async () => {
        const params = {
            program_id: program_id?.value,
        };
        const ret = await axiosPost('/admin/subject/getList', params);
        if (ret.status == 200) {
            const subjects = ret.data.data;
            const tmp = formatSelect(subjects, 'name', 'id');
            setSubjects(tmp);

            setValue(
                'subject_id',
                tmp.filter((item) => item.value == node.params.subject_id)[0]
            );
        } else {
            toast.error(ret.data.message);
        }
    };

    const getThemes = async () => {
        const params = {
            subject_id: subject_id?.value,
        };
        const ret = await axiosPost('/admin/theme/getList', params);
        if (ret.status == 200) {
            const themes = ret.data.data;
            const tmp = formatSelect(themes, 'name', 'id');
            setThemes(tmp);

            setValue(
                'theme_id',
                tmp.filter((item) => item.value == node.params.theme_id)[0]
            );
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (_data) => {
        const swalResponse = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to move this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, move it!',
            cancelButtonText: 'No, cancel!',
        });

        if (!swalResponse.isConfirmed) {
            return false;
        }

        let targetModelId = null;

        if (node.model === 'Program') {
            targetModelId = faculty_id.value;
        } else if (node.model === 'Subject') {
            targetModelId = program_id.value;
        } else if (node.model === 'Theme') {
            targetModelId = subject_id.value;
        }

        if (!targetModelId) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a valid target!',
            });
            return;
        }

        const ret = await axiosPost('/admin/organisation/update', {
            sourceModel: node.model,
            sourceModelId: node.modelId,
            targetModelId: targetModelId,
        });

        if (ret.status !== 200) {
            toast.error(ret.data.message);
            return false;
        }

        return true;
    };

    const handleSaveExit = async (data) => {
        const success = await onSubmit(data);

        if (success) {
            refresh();
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
                sx={{
                    '& .MuiDialog-paper': {
                        overflow: 'visible',
                    },
                }}
            >
                <form className="overflow-auto p-4" ref={form}>
                    <DialogTitle>
                        Move {node.model.replaceAll('Program', 'Programme')} (
                        {node.text}) To:
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                {node.params.faculty_id && (
                                    <Controller
                                        name="faculty_id"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div
                                                className="mb-9 w-full"
                                                style={{ position: 'relative' }}
                                            >
                                                <label>Faculty</label>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        options={faculties}
                                                        styles={{
                                                            control: (
                                                                baseStyles
                                                            ) => ({
                                                                ...baseStyles,
                                                                background:
                                                                    'transparent',
                                                            }),
                                                            menu: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 1500,
                                                            }),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 1500,
                                                            }),
                                                            container: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                width: '100%',
                                                            }),
                                                        }}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        classNames={{
                                                            singleValue: () =>
                                                                'text-black dark:text-white',
                                                            menuList: () =>
                                                                'text-black',
                                                            input: () =>
                                                                'text-black dark:text-white',
                                                        }}
                                                    />
                                                </Box>
                                                {errors.faculty_id && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.faculty_id
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}

                                {node.params.program_id && (
                                    <Controller
                                        name="program_id"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div
                                                className="mb-9 w-full"
                                                style={{ position: 'relative' }}
                                            >
                                                <label>Programme</label>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        options={programs}
                                                        styles={{
                                                            control: (
                                                                baseStyles
                                                            ) => ({
                                                                ...baseStyles,
                                                                background:
                                                                    'transparent',
                                                            }),
                                                            menu: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 1500,
                                                            }),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 1500,
                                                            }),
                                                            container: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                width: '100%',
                                                            }),
                                                        }}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        classNames={{
                                                            singleValue: () =>
                                                                'text-black dark:text-white',
                                                            menuList: () =>
                                                                'text-black',
                                                            input: () =>
                                                                'text-black dark:text-white',
                                                        }}
                                                    />
                                                </Box>
                                                {errors.program_id && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.program_id
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                                {node.params.subject_id && (
                                    <Controller
                                        name="subject_id"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div
                                                className="mb-9 w-full"
                                                style={{ position: 'relative' }}
                                            >
                                                <label>Subject</label>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        options={subjects}
                                                        styles={{
                                                            control: (
                                                                baseStyles
                                                            ) => ({
                                                                ...baseStyles,
                                                                background:
                                                                    'transparent',
                                                            }),
                                                            menu: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 1500,
                                                            }),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 1500,
                                                            }),
                                                            container: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                width: '100%',
                                                            }),
                                                        }}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        classNames={{
                                                            singleValue: () =>
                                                                'text-black dark:text-white',
                                                            menuList: () =>
                                                                'text-black',
                                                            input: () =>
                                                                'text-black dark:text-white',
                                                        }}
                                                    />
                                                </Box>
                                                {errors.subject_id && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.subject_id
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                                {node.params.theme_id && (
                                    <Controller
                                        name="theme_id"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div
                                                className="mb-9 w-full"
                                                style={{ position: 'relative' }}
                                            >
                                                <label>Theme</label>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        options={themes}
                                                        styles={{
                                                            control: (
                                                                baseStyles
                                                            ) => ({
                                                                ...baseStyles,
                                                                background:
                                                                    'transparent',
                                                            }),
                                                            menu: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 1500,
                                                            }),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 1500,
                                                            }),
                                                            container: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                width: '100%',
                                                            }),
                                                        }}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        classNames={{
                                                            singleValue: () =>
                                                                'text-black dark:text-white',
                                                            menuList: () =>
                                                                'text-black',
                                                            input: () =>
                                                                'text-black dark:text-white',
                                                        }}
                                                    />
                                                </Box>
                                                {errors.theme_id && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.theme_id
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit(handleSaveExit)}>
                            Save and Close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default MoveModal;
