import React, { useState, useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { axiosPost, fetchImage } from '@/services/axios';
import { saveSetting } from '../redux/actions';
import { Backdrop, CircularProgress } from '@mui/material';

export default function GuestLayout() {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const setting = useSelector((state) => state.setting);
    const [bgImage, setBgImage] = useState();
    const [loading, setLoading] = useState(true);

    // if user is logged in, redirect to profile page

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Prevent F12 and Ctrl+Shift+I
            if (
                e.key === 'F12' ||
                ((e.ctrlKey || e.metaKey) &&
                    e.shiftKey &&
                    (e.key === 'I' || e.key === 'i'))
            ) {
                e.preventDefault();
            }
        };

        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleSelectStart = (e) => {
            e.preventDefault();
        };

        const handleCopy = (e) => {
            e.preventDefault();
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('selectstart', handleSelectStart);
        document.addEventListener('copy', handleCopy);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('selectstart', handleSelectStart);
            document.removeEventListener('copy', handleCopy);
        };
    }, []);

    // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        getSetting();
    }, []);

    const getSetting = async () => {
        const ret = await axiosPost('/getSetting');
        if (ret.status == 200) {
            dispatch(saveSetting(ret.data.data));
        }
    };

    useEffect(() => {
        if (setting) {
            const bgImage = setting?.main_bg_image;
            if (bgImage) {
                getBGImage(bgImage);
            }
        }
    }, [setting]);

    const getBGImage = async (url) => {
        setLoading(true);
        const _image = await fetchImage(url);
        setBgImage(_image);
        setLoading(false);
    };

    if (user != undefined) {
        return <Navigate to="/welcome" />;
    }
    return (
        <div
            className=""
            style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
            }}
        >
            <Backdrop
                sx={{
                    color: '#fefefe',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <Outlet />
        </div>
    );
}
