import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Backdrop,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'dnd-multi-backend';
import { getBackendOptions, Tree } from '@minoru/react-dnd-treeview';
import {
    ChevronRight,
    DriveFileMove,
    Edit,
    ExpandMore,
    Folder,
    InsertDriveFile,
    ListAlt,
    ManageSearch,
    Save,
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BrushIcon from '@mui/icons-material/Brush';
import Swal from 'sweetalert2';
import { TrashIcon } from '@heroicons/react/24/outline';
import DeleteIcon from '@mui/icons-material/Delete';
import Counter from './Counter.jsx';
import { debounce } from 'lodash';
import MoveModal from './MoveModal.jsx';

const OrganisationPage = () => {
    const [loading, setLoading] = useState(true);
    const [treeData, setTreeData] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [facultyId, setFacultyId] = useState('');
    const [draggingNode, setDraggingNode] = useState(null);
    const [counters, setCounters] = useState({});
    const [term, setTerm] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [moveNode, setMoveNode] = useState(null);
    const [showMoveModal, setShowMoveModal] = useState(false);

    useEffect(() => {
        window.addEventListener('dragend', () => {
            setDraggingNode(null);
        });

        getFaculties();
    }, []);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);
    }, [facultyId, term]);

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            setFaculties(faculties);

            if (faculties.length > 0 && !facultyId) {
                setFacultyId(faculties[0].id);
            }
        } else {
            toast.error(ret.data.message);
        }
    };

    const getData = async () => {
        setLoading(true);
        try {
            const ret = await axiosPost('/admin/organisation/getData', {
                facultyId: facultyId,
                term: term,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            setTreeData(ret.data.tree);
            setCounters(ret.data.counters);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleUpdateTree = async (dragSource, dropTarget) => {
        setLoading(true);

        try {
            const ret = await axiosPost('/admin/organisation/update', {
                sourceModel: dragSource.model,
                sourceModelId: dragSource.modelId,
                targetModelId: dropTarget.modelId,
            });

            if (ret.status !== 200) {
                setLoading(false);
                return toast.error(ret.data.message);
            }

            setLoading(false);

            getData();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
            setLoading(false);
        }
    };

    const modelIcon = (node) => {
        switch (node.model) {
            case 'Faculty':
                return <AccountBalanceIcon />;
            case 'Program':
                return <LibraryBooksIcon />;
            case 'Subject':
                return <BookmarkIcon />;
            case 'Theme':
                return <BrushIcon />;
            default:
                return <InsertDriveFile />;
        }
    };

    const canExpand = (node) => {
        return treeData.some((item) => item.parent === node.id);
    };

    const handleNameEdit = async (node) => {
        let swalResponse = await Swal.fire({
            title: 'Edit Name',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
            },
            inputValue: node.text,
            showCancelButton: true,
            confirmButtonText: 'Save',
            showLoaderOnConfirm: true,
            preConfirm: (text) => {
                const newTreeData = treeData.map((item) => {
                    if (item.id === node.id) {
                        return { ...item, text: text };
                    }

                    return item;
                });

                setTreeData(newTreeData);

                axiosPost('/admin/organisation/update', {
                    sourceModel: node.model,
                    sourceModelId: node.modelId,
                    name: text,
                })
                    .then((ret) => {
                        if (ret.status !== 200) {
                            return toast.error(ret.data.message);
                        }

                        toast.success('Name updated successfully');
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                        });
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
    };

    const handleDelete = (node) => async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (!result.isConfirmed) return;

            axiosPost('/admin/organisation/delete', {
                sourceModel: node.model,
                sourceModelId: node.modelId,
            }).then(async (ret) => {
                if (ret.status !== 200) {
                    return toast.error(ret.data.message);
                }

                await getData();
                toast.success('Deleted successfully');
            });
        });
    };

    const handleMove = () => {
        setShowMoveModal(false);
        getData();
    };

    const EditableField = ({ node }) => {
        const inputRef = useRef(null);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, [node.text]);

        const handleInspectClick = (node) => {
            let url = '';

            switch (node.model) {
                case 'Program':
                    url = `#/admin/premise?program_id=${node.modelId}&faculty_id=${facultyId}`;
                    break;
                case 'Subject':
                    url = `#/admin/premise?subject_id=${node.modelId}&faculty_id=${facultyId}`;
                    break;
                case 'Theme':
                    url = `#/admin/premise?theme_id=${node.modelId}&faculty_id=${facultyId}`;
                    break;
                default:
                    url = '#';
                    break;
            }

            const tab = window.open(url, '_blank');
            tab.focus();
        };

        return (
            <div className={'flex w-full items-center justify-between'}>
                <div className={'flex flex-col'}>
                    <div>{node.text}</div>
                    <div className={'flex gap-1'}>
                        {Object.entries(node.counts).map(([key, value]) => (
                            <div key={key} className={'text-xs capitalize'}>
                                {key}: {value}
                            </div>
                        ))}
                    </div>
                </div>

                <div className={'flex gap-2'}>
                    <IconButton
                        onClick={() => {
                            setMoveNode(node);
                            setShowMoveModal(true);
                        }}
                    >
                        <DriveFileMove className="size-4" />
                    </IconButton>

                    <IconButton onClick={() => handleInspectClick(node)}>
                        <ManageSearch className="size-4" />
                    </IconButton>
                    <IconButton onClick={() => handleNameEdit(node)}>
                        <Edit className="size-4" />
                    </IconButton>
                    <IconButton onClick={handleDelete(node)}>
                        <DeleteIcon className="size-4 text-red-500" />
                    </IconButton>
                </div>
            </div>
        );
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full py-4 dark:bg-transparent">
                <div className="flex items-center justify-between">
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Organisation
                    </Typography>

                    <Counter counters={counters} />
                </div>
            </div>

            <div className={'my-5 flex gap-2'}>
                <FormControl size="small">
                    <InputLabel id="demo-select-small-label">
                        Faculty
                    </InputLabel>
                    <Select
                        size={'small'}
                        id="demo-select-small"
                        label={'Faculty'}
                        value={facultyId}
                        onChange={(e) => setFacultyId(e.target.value)}
                    >
                        {faculties.map((item) => (
                            <MenuItem key={Math.random()} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small">
                    <TextField
                        size={'small'}
                        label={'Term'}
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                    />
                </FormControl>
            </div>

            <div className={'overflow-hidden rounded-md'}>
                <DndProvider
                    backend={MultiBackend}
                    options={getBackendOptions()}
                >
                    <Tree
                        tree={treeData}
                        rootId={0}
                        onDrop={(newTreeData, { dragSource, dropTarget }) => {
                            setTreeData(newTreeData);
                            handleUpdateTree(dragSource, dropTarget);
                            setDraggingNode(null);
                        }}
                        canDrop={(tree, { dragSource, dropTarget }) => {
                            setDraggingNode(dragSource);

                            return (
                                dragSource?.parentModel === dropTarget?.model
                            );
                        }}
                        enableAnimateExpand={true}
                        render={(node, { depth, isOpen, onToggle }) => (
                            <div
                                className={`flex cursor-pointer items-center gap-2 border-b bg-gray-50 p-4 shadow transition-all hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-600 dark:hover:bg-gray-700 ${draggingNode && draggingNode.parentModel !== node.model ? 'opacity-10' : ''}`}
                                style={{ paddingLeft: depth * 36 }}
                            >
                                <button
                                    onClick={onToggle}
                                    className={`text-gray-600 hover:text-gray-800 focus:outline-none dark:text-gray-400 dark:hover:text-gray-200 ${canExpand(node) ? '' : 'opacity-0'}`}
                                >
                                    {isOpen ? <ExpandMore /> : <ChevronRight />}
                                </button>

                                {modelIcon(node)}

                                <EditableField node={node} />
                            </div>
                        )}
                    />
                </DndProvider>
            </div>

            {showMoveModal && (
                <MoveModal
                    setOpenModal={setShowMoveModal}
                    openModal={showMoveModal}
                    node={moveNode}
                    faculties={faculties}
                    refresh={handleMove}
                />
            )}
        </div>
    );
};

export default OrganisationPage;
