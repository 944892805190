import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import router from './router';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './redux/store';
import { axiosPost } from './services/axios';
import { Backdrop, CircularProgress } from '@mui/material';

export const ColorModeContext = React.createContext({
    toggleColorMode: (color) => {},
});

function App() {
    const [mode, setMode] = React.useState('dark');
    const [loading, setLoading] = useState(true);
    const { user, checkSession } = useAuth();

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: (color) => {
                setMode(color);
            },
        }),
        []
    );

    useEffect(() => {
        const verifySession = async () => {
            await checkSession();
            setLoading(false);
        };
        verifySession();
    }, [checkSession]);

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode]
    );

    if (loading) {
        return (
            <Backdrop
                sx={{
                    color: 'gray',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
    return (
        // <React.StrictMode>
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <Provider store={store}>
                        <RouterProvider router={router} />
                    </Provider>
                </AuthProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
        // </React.StrictMode>
    );
}
export default App;

const rootElement = document.getElementById('app');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<App />);
}
