import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import CreateEditModal from './CreateEditModal';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { api_url } from '../../../constant';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Toolbar from '@mui/material/Toolbar';
import DeleteModal from './DeleteModal';

export const SourceTypeList = [
    {
        value: 'Book (Full)',
    },
    {
        value: 'Book (Chapter)',
    },
    {
        value: 'Chapter (Edited Volume)',
    },
    {
        value: 'Journal Article',
    },
    {
        value: 'Conference Paper',
    },
    {
        value: 'Other',
    },
];

const headCells = [
    {
        id: 'source_type',
        numeric: false,
        sortable: true,
        label: 'Source Type',
    },
    {
        id: 'year_of_publication',
        numeric: true,
        sortable: true,
        label: 'Year of Publication',
    },
    {
        id: 'type_of_publication',
        numeric: true,
        sortable: false,
        label: 'Type of Publication',
    },
    {
        id: 'author_name',
        numeric: true,
        sortable: false,
        label: 'Author last names',
    },
    {
        id: 'title',
        numeric: true,
        sortable: false,
        label: 'Title',
    },
    {
        id: 'keywords',
        numeric: true,
        sortable: false,
        label: 'Keywords',
    },
    {
        id: 'created_at',
        numeric: true,
        sortable: false,
        label: 'Created At',
    },
    {
        id: 'updated_at',
        numeric: true,
        sortable: false,
        label: 'Updated At',
    },
    {
        id: 'count_premise',
        numeric: true,
        sortable: false,
        label: 'Number of Premises',
    },
    {
        id: 'sr_click',
        numeric: true,
        sortable: false,
        label: 'References clicks',
    },
    {
        id: 'action',
        numeric: false,
        sortable: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected, handleDelete, handleExport } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={(e) => handleDelete()}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Download Sources">
                    <Button
                        variant="contained"
                        color="warning"
                        style={{
                            color: 'white',
                        }}
                        onClick={(e) => handleExport()}
                    >
                        {' '}
                        <FileDownloadIcon />
                    </Button>
                </Tooltip>
            )}
        </Toolbar>
    );
}

const SourcesPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [sourceType, setSourceType] = useState('all');
    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');

    const [selected, setSelected] = React.useState([]);

    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editItem, setEditItem] = useState();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [
        searchKey,
        sourceType,
        title,
        author,
        page,
        rowsPerPage,
        orderBy,
        order,
    ]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                searchKey,
                sourceType,
                page,
                pageSize: rowsPerPage,
                orderBy,
                order,
                title,
                author,
            };
            const ret = await axiosPost('/admin/source/getList', params);
            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setRows(list);
                setTotal(total);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onCreated = () => {
        toast.success('Create Source Successfully!');
        getData();
    };

    const handleEdit = (item) => {
        setEditItem(item);
        setOpenEditModal(true);
    };

    const onUpdated = () => {
        toast.success('Update Source Successfully!');
        getData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderKeywords = (keywords) => {
        try {
            const arr = JSON.parse(keywords);
            return arr.join(', ');
        } catch (err) {
            return '';
        }
    };

    const renderLastName = (names) => {
        try {
            const arr = JSON.parse(names);
            const lasts = arr.map((name) => name.last);
            return lasts.join(', ');
        } catch (err) {
            return '';
        }
    };

    const openExport = () => {
        let url = api_url + `/admin/source/export`;
        window.open(url, '_blank');
    };

    const handleDelete = async () => {
        if (selected.length > 0) {
            setOpenDeleteModal(true);
        }
    };

    const handleRowClick = (source_id) => {
        // go to premise for source
        const newTab = window.open(`/#/admin/premise/${source_id}`, '_blank');
        newTab.focus();
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Sources
                    </Typography>
                    <div className="flex">
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ mr: 2 }}
                            style={{
                                color: 'white',
                            }}
                            onClick={(e) => setOpenModal(true)}
                        >
                            New Source
                        </Button>
                    </div>
                </div>
                <Box
                    component="fieldset"
                    className="rounded-md border border-slate-500 p-3 py-5"
                >
                    <legend className="ml-3 flex">
                        <div className="w-5"></div>
                        {' Filter '} <div className="w-5"></div>
                    </legend>
                    <div className="flex flex-wrap gap-2">
                        <FormControl size="small">
                            <InputLabel id="demo-select-small-label">
                                Type
                            </InputLabel>
                            <Select
                                className="min-w-[200px]"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={sourceType}
                                label="Type"
                                onChange={(e) => setSourceType(e.target.value)}
                            >
                                <MenuItem key={Math.random()} value={'all'}>
                                    All
                                </MenuItem>
                                {SourceTypeList.map((item) => (
                                    <MenuItem
                                        key={Math.random()}
                                        value={item.value}
                                    >
                                        {item.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            className="min-w-[200px]"
                            value={author}
                            label="Author"
                            size="small"
                            onChange={(e) => setAuthor(e.target.value)}
                        />

                        <TextField
                            className="min-w-[200px]"
                            value={title}
                            label="Title"
                            size="small"
                            onChange={(e) => setTitle(e.target.value)}
                        />

                        <TextField
                            label="Keyword"
                            value={searchKey}
                            size="small"
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    </div>
                </Box>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={12}></Grid>
                </Grid>

                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleExport={openExport}
                    handleDelete={handleDelete}
                />
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        key={index}
                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) =>
                                                    handleClick(event, row.id)
                                                }
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.source_type}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.year_of_publication}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.type_of_publication}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {renderLastName(row.author_name)}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.title}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {renderKeywords(row.keywords)}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {dayjs(row.created_at).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {dayjs(row.updated_at).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                            )}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.count_premise}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.sr_click}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEdit(row.id)
                                                }
                                            >
                                                <EditIcon className="h-[19px] w-[22px]" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {openModal && (
                    <CreateEditModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        refresh={onCreated}
                        title={'Create Source'}
                    />
                )}

                {openEditModal && (
                    <CreateEditModal
                        openModal={openEditModal}
                        setOpenModal={setOpenEditModal}
                        refresh={onUpdated}
                        id={editItem}
                        title={'Update Source'}
                    />
                )}

                {openDeleteModal && (
                    <DeleteModal
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                        refresh={getData}
                        ids={selected}
                    />
                )}
            </div>
        </div>
    );
};

export default SourcesPage;
