import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import { Textarea } from '@material-tailwind/react';

const schema = yup.object().shape({
    batchSize: yup.number().required().min(1),
    program_prompt: yup.string().required(),
    subject_prompt: yup.string().required(),
    theme_prompt: yup.string().required(),
});

const BulkAiAutoPopulateModal = ({
    setOpenModal,
    openModal,
    refresh,
    ids,
    fileName,
}) => {
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ids: ids,
            batchSize: 3,
            program_prompt: '',
            subject_prompt: '',
            theme_prompt: '',
            file_name: '',
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const [settings, setSettings] = useState(null);

    useEffect(() => {
        axiosPost('/admin/setting/getData').then((ret) => {
            if (ret.status !== 200) {
                toast.error(ret.data.message);
                return;
            }

            setSettings(ret.data.data);

            setValue('program_prompt', ret.data.data.program_prompt);
            setValue('subject_prompt', ret.data.data.subject_prompt);
            setValue('theme_prompt', ret.data.data.theme_prompt);
        });
    }, []);

    const form = useRef(null);

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenModal(false);
    };

    const onSubmit = (_data) => {
        axiosPost('/admin/premise/auto-populate', {
            ids: ids,
            batchSize: _data.batchSize,
            programPrompt: _data.program_prompt,
            subjectPrompt: _data.subject_prompt,
            themePrompt: _data.theme_prompt,
            fileName: _data.file_name,
        })
            .then((ret) => {
                if (ret.status !== 200) {
                    toast.error(ret.data.message);
                    return;
                }

                refresh();
                setOpenModal(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleSavePrompt = (field) => {
        axiosPost('/admin/setting/update', {
            [field]: watch(field),
        }).then((ret) => {
            if (ret.status !== 200) {
                toast.error(ret.data.message);
                return;
            }

            let set = { ...settings };
            set[field] = watch(field);
            setSettings(set);

            toast.success('Prompt saved');
        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
                sx={{
                    '& .MuiDialog-paper': {
                        overflow: 'visible',
                    },
                }}
            >
                <form className="overflow-y-scroll p-4" ref={form}>
                    <DialogTitle>AI Auto Populate</DialogTitle>
                    <DialogContent sx={{ overflow: 'visible' }}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <div className={'flex flex-col gap-4'}>
                                    <Textarea
                                        label="Programme Prompt"
                                        {...register('program_prompt')}
                                        className={'text-black dark:text-white'}
                                        rows={5}
                                    />

                                    {settings &&
                                        settings.program_prompt !==
                                            watch('program_prompt') && (
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                className={'!mb-4 !mt-1 w-max'}
                                                onClick={() => {
                                                    handleSavePrompt(
                                                        'program_prompt'
                                                    );
                                                }}
                                            >
                                                Save
                                            </Button>
                                        )}

                                    <Textarea
                                        label="Subject Prompt"
                                        {...register('subject_prompt')}
                                        className={'text-black dark:text-white'}
                                        rows={5}
                                    />

                                    {settings &&
                                        settings.subject_prompt !==
                                            watch('subject_prompt') && (
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                className={'!mb-4 !mt-1 w-max'}
                                                onClick={() => {
                                                    handleSavePrompt(
                                                        'subject_prompt'
                                                    );
                                                }}
                                            >
                                                Save
                                            </Button>
                                        )}

                                    <Textarea
                                        label="Theme Prompt"
                                        rows={5}
                                        className={'text-black dark:text-white'}
                                        {...register('theme_prompt')}
                                    />

                                    {settings &&
                                        settings.theme_prompt !==
                                            watch('theme_prompt') && (
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                className={'!mb-4 !mt-1 w-max'}
                                                onClick={() => {
                                                    handleSavePrompt(
                                                        'theme_prompt'
                                                    );
                                                }}
                                            >
                                                Save
                                            </Button>
                                        )}

                                    <TextField
                                        type={'number'}
                                        label="Batch Size"
                                        className={'text-black dark:text-white'}
                                        step={1}
                                        min={1}
                                        {...register('batchSize')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Typography className="text-sm" sx={{ my: 2 }}>
                            Are you sure you want auto populate {ids.length}{' '}
                            premises?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
                        <Button onClick={handleSubmit(onSubmit)}>
                            Populate
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default BulkAiAutoPopulateModal;
