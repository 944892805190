import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon, QueueListIcon } from '@heroicons/react/24/outline';
import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {
    ArrowPathIcon,
    DocumentTextIcon,
} from '@heroicons/react/24/outline/index.js';
import DnsIcon from '@mui/icons-material/Dns.js';
import { GridViewColumnIcon } from '@mui/x-data-grid';
import {
    ViewColumnOutlined,
    ViewColumnRounded,
    ViewHeadlineOutlined,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

export default function Filters({
    showSort = true,
    setSort,
    showType = false,
    setType,
    showSourceType = true,
    setSourceType,
    showPageSort = false,
    setPageSort,
    setGridView,
    showGridView = false,
    keyword,
    setKeyword,
    searchPlaceHolder,
}) {
    const timer = useRef(0);

    const [searchKey, setSearchKey] = useState(keyword);

    const search = (inputValue) => {
        clearTimeout(timer.current);
        setSearchKey(inputValue);
        timer.current = setTimeout(function () {
            setKeyword(inputValue);
        }, 500);
    };

    const [sourceType, _setSourceType] = useState('all');
    const [pageSort, _setPageSort] = useState('asc');
    const [sort, _setSort] = useState('premises');
    const [type, _setType] = useState('all');
    const [gridView, _setGridView] = useState(true);

    useEffect(() => {
        if (setSourceType) setSourceType(sourceType);
    }, [sourceType]);

    useEffect(() => {
        if (setSort) setSort(sort);
    }, [sort]);

    useEffect(() => {
        if (setPageSort) {
            setPageSort(pageSort);
        }
    }, [pageSort]);

    useEffect(() => {
        if (setType) setType(type);
    }, [type]);

    useEffect(() => {
        if (setGridView) setGridView(gridView);
    }, [gridView]);

    return (
        <Grid container spacing={2} className="mb-4">
            <Grid item xs={12} lg={5}>
                <div className="flex w-full gap-2">
                    <div className={'rounded-md border border-white'}>
                        <IconButton onClick={() => window.history.back()}>
                            <ArrowLeftIcon className="h-6 w-6" />
                        </IconButton>
                    </div>
                    <TextField
                        fullWidth
                        className="flex-1"
                        size="small"
                        InputProps={{
                            className: 'flex-1 w-full',
                            style: { color: 'black', background: 'white' },
                        }}
                        placeholder={searchPlaceHolder}
                        // placeholder="Search for Themes, Premises, Key Words…"
                        onChange={(e) => search(e.target.value)}
                        value={searchKey}
                    />
                </div>
            </Grid>
            <Grid item xs={12} lg={7}>
                <div className={'flex flex-row justify-end gap-2'}>
                    {showSourceType && (
                        <div>
                            <Select
                                fullWidth
                                size="small"
                                className="mr-2 rounded"
                                value={sourceType}
                                onChange={(e) => {
                                    _setSourceType(e.target.value);
                                }}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    '.MuiSvgIcon-root ': {
                                        fill: 'black !important',
                                    },
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: 'All Source Types',
                                    },
                                    {
                                        value: 'empirical',
                                        label: 'Empirical Sources Only',
                                    },
                                    {
                                        value: 'theoretical',
                                        label: 'Theoretical Sources Only',
                                    },
                                    {
                                        value: 'Empirical and Theoretical',
                                        label: 'Empirical and Theoretical',
                                    },
                                    {
                                        value: 'Other',
                                        label: 'Other',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {showPageSort && (
                        <div>
                            <Select
                                fullWidth
                                size="small"
                                value={pageSort}
                                className="mr-2 rounded"
                                onChange={(e) => _setPageSort(e.target.value)}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    '.MuiSvgIcon-root ': {
                                        fill: 'black !important',
                                    },
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'asc',
                                        label: 'Page Range (low to high)',
                                    },
                                    {
                                        value: 'desc',
                                        label: 'Page Range (high to low)',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {showSort && (
                        <div>
                            <Select
                                value={sort}
                                fullWidth
                                size="small"
                                className="rounded"
                                onChange={(e) => _setSort(e.target.value)}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    '.MuiSvgIcon-root ': {
                                        fill: 'black !important',
                                    },
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'recent',
                                        label: 'Most Recent',
                                    },
                                    {
                                        value: 'premises',
                                        label: 'Most Premises',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {showType && (
                        <div>
                            <Select
                                fullWidth
                                size="small"
                                value={type}
                                onChange={(e) => _setType(e.target.value)}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    '.MuiSvgIcon-root ': {
                                        fill: 'black !important',
                                    },
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: 'All Premise Types',
                                    },
                                    {
                                        value: 'From Secondary Literature',
                                        label: 'From Secondary Literature',
                                    },
                                    {
                                        value: 'Empirical Finding',
                                        label: 'Empirical Finding',
                                    },
                                    {
                                        value: 'Other',
                                        label: 'Other',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {showGridView && (
                        <div className={'rounded-md border-1 border-white'}>
                            <IconButton onClick={() => _setGridView(!gridView)}>
                                {gridView && (
                                    <ViewHeadlineOutlined className="size-6" />
                                )}

                                {!gridView && (
                                    <ViewColumnRounded className="size-6" />
                                )}
                            </IconButton>
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
