import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Login from '../component/Login';
import Signup from '../component/Signup';
import { Navigate, Link, useSearchParams } from 'react-router-dom';
import BackgroundImage from '../images/cover/sourcebank_bg.jpg';
import { Grid } from '@mui/material';
import ScrollToTop from '../component/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { axiosPost, fetchImage } from '../services/axios';
import { toast } from 'react-toastify';
import LearnMore from '../component/LearnMore';

export default function Home() {
    const [status, setStatus] = useState();
    const [session_id, setSessionId] = useState();

    useEffect(() => {
        // Function to parse query parameters from URL
        const parseUrlParams = () => {
            const searchParams = new URLSearchParams(window.location.hash);
            const _sessionId = searchParams.get('session_id');
            const _status = searchParams.get('status');
            setSessionId(_sessionId);
            setStatus(_status);
        };

        // Call the function when component mounts
        parseUrlParams();
    }, []);

    useEffect(() => {
        if (status != undefined && status == 'success') {
            toast.success('Email verification sent');
        } else if (status == 'cancel') {
            toast.error('Cancel Subscription');
        }
    }, [status]);

    useEffect(() => {
        if (session_id != undefined && status != undefined) {
            subscriptionResult();
        }
    }, [session_id, status]);

    const subscriptionResult = async () => {
        try {
            const params = {
                status,
                session_id,
            };
            const ret = await axiosPost('/subscriptionResult', params);
        } catch (err) {
            console.log(err.response.data.message);
        }
    };

    const [value, setValue] = useState('login');
    const [home, setHome] = useState(0);

    useEffect(() => {
        if (home != 0) {
            console.log(home);
        }
    }, [home]);

    const elementRef = useRef();

    const [trigger, setTrigger] = useState(false);

    let sTimer = null;
    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current == undefined) {
                return;
            }
            console.log(elementRef.current.getBoundingClientRect().top);
            if (
                elementRef.current &&
                elementRef.current.getBoundingClientRect().top < -100
            ) {
                // User has scrolled down
                setTrigger(true);
            } else {
                setTrigger(false);
            }
        };
        sTimer = setInterval(() => {
            handleScroll();
        }, 500);
        return () => {
            if (sTimer) {
                clearInterval(sTimer);
            }
        };
    }, []);

    return (
        <main className="flex flex-col">
            {/* <!-- Hero --> */}
            {value == 'login' && (
                <Login changeCard={setValue} navigate={setHome} />
            )}
            {value == 'register' && <Signup changeCard={setValue} />}
            {value == 'learn' && <LearnMore changeCard={setValue} />}
        </main>
    );
}
