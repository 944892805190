import React, { useState, useEffect, useCallback } from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';

import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import {
    ArrowDownIcon,
    ArrowPathIcon,
    ArrowsUpDownIcon,
    DocumentTextIcon,
    EyeIcon,
} from '@heroicons/react/24/outline/index.js';
import { Check, WarningOutlined } from '@mui/icons-material';
import { debounce } from 'lodash';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableCellsIcon } from '@heroicons/react/16/solid/index.js';
import DnsIcon from '@mui/icons-material/Dns';
import { TrashIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ShowQueuedJob = () => {
    const [loading, setLoading] = useState(false);
    const [queuedJob, setQueuedJob] = useState(null);
    const [autoUpdateInterval, setAutoUpdateInterval] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (['failed', 'finished'].includes(queuedJob?.status)) {
            clearInterval(autoUpdateInterval);
            setAutoUpdateInterval(null);
        } else {
            setAutoUpdateInterval(
                setInterval(() => {
                    axiosPost('/admin/queued-jobs/getData', {
                        id,
                    }).then((ret) => {
                        setQueuedJob(ret.data);
                    });
                }, 5000)
            );
        }
    }, [queuedJob?.status]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                id,
            };

            const ret = await axiosPost('/admin/queued-jobs/getData', params);

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            setQueuedJob(ret.data);
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <div className="w-full rounded bg-white dark:bg-opacity-10">
                    <div className="flex items-center border-b px-2 py-4">
                        <Typography variant="h6">Queued Job</Typography>
                    </div>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>{queuedJob?.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Job Name</TableCell>
                                    <TableCell>
                                        {queuedJob?.job_display_name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell className={'capitalize'}>
                                        {queuedJob?.status}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Started At</TableCell>
                                    <TableCell>
                                        {queuedJob?.started_at}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Finished At</TableCell>
                                    <TableCell>
                                        {queuedJob?.finished_at}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>
                                        {queuedJob?.created_at}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className="mt-4 w-full rounded bg-white dark:bg-opacity-10">
                    <div className="flex items-center border-b px-2 py-4">
                        <Typography variant="h6">Arguments</Typography>
                    </div>

                    <div className="p-2">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Key</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {queuedJob?.arguments &&
                                        Object.entries(queuedJob.arguments).map(
                                            ([key, value], index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{key}</TableCell>
                                                    <TableCell>
                                                        {value}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <div className="mt-4 w-full rounded bg-white dark:bg-opacity-10">
                    <div className="flex items-center border-b px-2 py-4">
                        <Typography variant="h6">Logs</Typography>
                    </div>

                    <div className={'h-96 overflow-scroll p-4'}>
                        <pre>{queuedJob?.console_output}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowQueuedJob;
