import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const schema = yup.object().shape({
    faculty_id: yup.string().required(),
});

const BulkEditModal = ({ setOpenModal, openModal, refresh, ids }) => {
    const [faculties, setFaculties] = useState([]);
    const [initF, setInitF] = useState(false);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            faculty_id: '',
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const form = useRef(null);

    const faculty_id = watch('faculty_id');

    useEffect(() => {
        getFaculties();
    }, []);

    useEffect(() => {
        if (faculty_id) {
            setInitF(true);
        }
    }, [faculty_id]);

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            setFaculties(faculties);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenModal(false);
    };

    const transform = (data) => {
        return {
            ...data,
            faculty_id: faculty_id,
        };
    };

    const onSubmit = async (_data) => {
        const data = transform(_data);
        let url = '/admin/premise/bulk-update';
        data['ids'] = ids;
        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    const submit = async (data) => {
        const success = await onSubmit(data);
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
                sx={{
                    '& .MuiDialog-paper': {
                        overflow: 'visible',
                    },
                }}
            >
                <form className="p-4" ref={form}>
                    <DialogTitle>Unallocated Premises Bulk Update</DialogTitle>
                    <DialogContent sx={{ overflow: 'visible' }}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <Controller
                                    name="faculty_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div
                                            className="mb-9 w-full"
                                            style={{ position: 'relative' }}
                                        >
                                            <label>Faculty (F)</label>
                                            <Select
                                                fullWidth
                                                size="small"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={value}
                                                onChange={onChange}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            position:
                                                                'absolute',
                                                            top: '100%',
                                                            left: 0,
                                                        },
                                                    },
                                                }}
                                            >
                                                {faculties.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.faculty_id && (
                                                <span className="text-rose-500">
                                                    {errors.faculty_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography className="text-sm" sx={{ my: 2 }}>
                            Are you sure you want to bulk update {ids.length}{' '}
                            premises?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
                        <Button onClick={handleSubmit(submit)}>Update</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default BulkEditModal;
